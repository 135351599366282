import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
  SET_LOGIN_ERROR_MESSAGE: type('[login error page] set message')
};

export class SetLoginErrorMessage implements Action {
  public type = ActionTypes.SET_LOGIN_ERROR_MESSAGE;

  constructor(public payload: string) {}
}

export type TypeAction =
  SetLoginErrorMessage;
